define("discourse/plugins/discourse-livestream/discourse/components/embeddable-chat-channel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/controller", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "truth-helpers/helpers/and", "truth-helpers/helpers/not", "discourse/plugins/chat/discourse/components/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _controller, _helper, _object, _didInsert, _service, _and, _not, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmbedableChatChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.inject]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "embeddableChat", [_service.inject]))();
    #embeddableChat = (() => (dt7948.i(this, "embeddableChat"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "chatDraftsManager", [_service.inject]))();
    #chatDraftsManager = (() => (dt7948.i(this, "chatDraftsManager"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "topicController", [(0, _controller.inject)("topic")]))();
    #topicController = (() => (dt7948.i(this, "topicController"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "topicModel", [_tracking.tracked], function () {
      return null;
    }))();
    #topicModel = (() => (dt7948.i(this, "topicModel"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "topicChannelId", [_tracking.tracked], function () {
      return null;
    }))();
    #topicChannelId = (() => (dt7948.i(this, "topicChannelId"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "loadingChannel", [_tracking.tracked], function () {
      return false;
    }))();
    #loadingChannel = (() => (dt7948.i(this, "loadingChannel"), void 0))();
    async findChannel(channelId) {
      try {
        this.loadingChannel = true;
        this.embeddableChat.activeChannel = await this.chatChannelsManager.find(channelId);
      } finally {
        this.loadingChannel = false;
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "findChannel", [_object.action]))();
    willDestroy() {
      super.willDestroy(...arguments);
      this.chatDraftsManager.reset();
      this.embeddableChat.activeChannel = null;
    }
    static #_12 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          id="custom-chat-container"
          class="chat-drawer"
          {{didInsert (fn this.findChannel @chatChannelId)}}
        >
          {{#if (and this.embeddableChat.activeChannel (not this.loadingChannel))}}
            <ChatChannel @channel={{this.embeddableChat.activeChannel}} />
          {{/if}}
        </div>
      
    */
    {
      "id": "Zi5Us6nV",
      "block": "[[[1,\"\\n    \"],[11,0],[24,1,\"custom-chat-container\"],[24,0,\"chat-drawer\"],[4,[32,0],[[28,[32,1],[[30,0,[\"findChannel\"]],[30,1]],null]],null],[12],[1,\"\\n\"],[41,[28,[32,2],[[30,0,[\"embeddableChat\",\"activeChannel\"]],[28,[32,3],[[30,0,[\"loadingChannel\"]]],null]],null],[[[1,\"        \"],[8,[32,4],null,[[\"@channel\"],[[30,0,[\"embeddableChat\",\"activeChannel\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@chatChannelId\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-livestream/discourse/components/embeddable-chat-channel.js",
      "scope": () => [_didInsert.default, _helper.fn, _and.default, _not.default, _chatChannel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EmbedableChatChannel;
});